import React  from "react"
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Tab from "react-bootstrap/Tab"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import ApplicationLinkCard from "../components/application-link-card"

const ApplyPage = props => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "applications/index.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      creativePlacemaking: file(relativePath: {eq: "applications/creative-places.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      communityPlan: file(relativePath: {eq: "applications/community-plan.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      healthyCommunity: file(relativePath: {eq: "applications/healthy-community.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nonProfitProject: file(relativePath: {eq: "applications/non-profit-project.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      preservationAward: file(relativePath: {eq: "applications/preservation-award.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      forProfitProject: file(relativePath: {eq: "applications/for-profit-project.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      powerOfCommunity: file(relativePath: {eq: "applications/power-of-community.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      driehausAward: file(relativePath: {eq: "applications/driehaus-award.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Container>
        <SEO title="Apply" />
        <PageTitle titleText="Apply to the Chicago Neighborhood Development Awards" />
        <Img
          fluid={data.banner.childImageSharp.fluid}
          className="mb-3"
          style={{maxHeight: "500px"}}
        />
        <div className="text-center">
          <p className="small">
            <em>
              <a
                href="https://map.lisc-cnda.org/2019/driehaus/city-gardens"
                target="_blank"
                rel="noopener noreferrer"
              >
                Landon Bone Baker for City Gardens
              </a>,
              a 2019 Driehaus Award First Place winner
            </em>
          </p>
        </div>
        <div className="col-md-10 offset-md-1 mb-5">
          <p>
            The <strong>Chicago Neighborhood Development Awards (CNDA)</strong> recognize outstanding achievement
            in neighborhood real estate development and community building, especially
            the achievements of community development corporations (CDCs), other
            community-based organizations, and for-profit developers working to build
            healthier neighborhoods in the Chicago metropolitan area.
          </p>
          <p>
            The <strong>Richard H. Driehaus Foundation Award for Architectural Excellence in Community Design</strong> was
            established to recognize the importance of great architecture and craftsmanship to city life.
          </p>
          <p>
            <a
              href="http://www.lisc-cnda.org/awards"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read more about each award, including the review and selection process &raquo;
            </a>
          </p>
        </div>
        <div className="col-sm-12">
          <h2 style={{fontFamily: "Source Serif Pro", marginBottom: '1.5rem'}}>Apply for an award:</h2>
          <Tab.Container defaultActiveKey="cnda" id="applyTabs">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="cnda">The Chicago Neighborhood Development Awards</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="driehaus-award">The Richard H. Driehaus Award for Architectural Excellence in Community Design</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="cnda">
                <hr/>
                <ApplicationLinkCard
                  applicationLink="/apply/creative-places"
                  imageLink="applications/creative-places.jpg"
                  applicationImage={data.creativePlacemaking.childImageSharp.fluid}
                  applicationTitle="The John D. and Catherine T. MacArthur Foundation Creative Places Award"
                  applicationDescription="The John D. and Catherine T. MacArthur Foundation Creative Places Award
                                        recognizes projects in the Chicago region that leverage the power of the arts,
                                        culture, and creativity to serve a community’s interest, while driving a broader
                                        agenda of change, growth and transformation in a way that builds character and
                                        quality of place."
                />

                <ApplicationLinkCard
                  applicationLink="/apply/community-plan"
                  applicationImage={data.communityPlan.childImageSharp.fluid}
                  applicationTitle="The Chicago Community Trust Outstanding Community Plan Award"
                  applicationDescription="The Chicago Community Trust Outstanding Community Plan Award
                                        recognizes the development and successful implementation of a
                                        community plan or strategy within Cook County that improves
                                        quality-of-life or addresses a specific need, opportunity or
                                        issue through a comprehensive planning and implementation model."
                />

                <ApplicationLinkCard
                  applicationLink="/apply/healthy-community"
                  applicationImage={data.healthyCommunity.childImageSharp.fluid}
                  applicationTitle="The Blue Cross Blue Shield of Illinois Healthy Community Award"
                  applicationDescription="The Blue Cross Blue Shield of Illinois Healthy
                                        Community Award recognizes a successful community- based effort to address
                                        the health of a low-to-moderate income neighborhood in the Chicago metropolitan
                                        area through creative and collaborative strategies."
                />

                <ApplicationLinkCard
                  applicationLink="/apply/non-profit-project"
                  applicationImage={data.nonProfitProject.childImageSharp.fluid}
                  applicationTitle="The Richard H. Driehaus Foundation Award for Outstanding Non-Profit
                                  Neighborhood Real Estate Project"
                  applicationDescription="The Richard H. Driehaus Foundation Award for
                                        Outstanding Non-Profit Neighborhood Real Estate
                                        Project recognizes a community development corporation
                                        for a specific real estate project that has contributed
                                        significantly to the enhancement of the community."
                />

                <ApplicationLinkCard
                  applicationLink="/apply/preservation-award"
                  applicationImage={data.preservationAward.childImageSharp.fluid}
                  applicationTitle="The Polk Bros. Foundation Affordable Rental Housing Preservation Award"
                  applicationDescription="The Polk Bros. Foundation Affordable Rental Housing Preservation
                                        Award recognizes a for-profit developer or a non-profit community
                                        development organization for a specific real estate project in the
                                        City of Chicago that has preserved affordable rental housing."
                />

                <ApplicationLinkCard
                  applicationLink="/apply/for-profit-project"
                  applicationImage={data.forProfitProject.childImageSharp.fluid}
                  applicationTitle="The Outstanding For-Profit Neighborhood Real Estate Project Award"
                  applicationDescription="The Outstanding For-Profit Neighborhood Real Estate Project Award
                                        recognizes a for-profit developer for a specific real estate project
                                        that has contributed significantly to the enhancement of the community."
                />
              </Tab.Pane>

              <Tab.Pane eventKey="driehaus-award" title="The Richard H. Driehaus Award for Architectural Excellence in Community Design">
                <hr/>
                <ApplicationLinkCard
                  applicationLink="/apply/driehaus-award"
                  applicationImage={data.driehausAward.childImageSharp.fluid}
                  applicationTitle="The Richard H. Driehaus Foundation Award for Architectural Excellence in Community Design"
                  applicationDescription="The Richard H. Driehaus Foundation Award for Architectural Excellence in Community
                                        Design was established to recognize the importance of great architecture and craftsmanship
                                        to city life."
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

        </div>
      </Container>
    </Layout>
  )
}

export default ApplyPage
